import OSS from 'ali-oss';
export async function mockUpload(file) {
    try {
        // 获取OSS配置信息
        let client = new OSS({
            accessKeyId: 'LTAI5tRfhxQSuk8eJt1RzetA',
            accessKeySecret: '4jtDtN6XAfUaBfKi2YPId0UtcyGkAE',
            bucket: 'yt-oss1',
            region: 'oss-cn-hangzhou',
            secure: true
        });
        const point = file.name.lastIndexOf('.');
        let suffix = file.name.substr(point);
        let fileName;
        if (point > 25) {
            fileName = file.name.substr(0, 25);
        } else {
            fileName = file.name.substr(0, point);
        }
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth() + 1;
        let dd = new Date().getDate();
        let hh = new Date().getHours();
        let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
        let date = yy + '-' + mm + '-' + dd + '-' + hh + '-' + mf + '-' + ss;
        let fileNames = `${fileName}_${date}${suffix}`;
        let relativePath = 'serviceItem/';
        return {
            url: (await client.put(relativePath + fileNames, file)).url,
          }
    } catch (error) {
        console.error(error);
    }
  }