import React from 'react';
import '../App.css';

function Next(props) {
  console.log(props)
  
  return (
    <div className="flex-center-center bottomTap">
      <div className="next" 
        style={{background:(props.title === '返回列表' ||  props.title === "联系医生" ? 'rgba(17,95,225,1)' : (props.data.isCheckList[props.data.indexNext].isCheckSome ? 'rgba(17,95,225,1)' : 'rgba(17,95,225,0.4)'))}}>
        <div className="nexttext" onClick={() => props.onSlickNext()}>
        {props.title}
        </div>
      </div>
    </div>
  );
}

export default Next;