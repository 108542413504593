import React from 'react';
import { Row, Col } from 'antd';
// import wx from 'weixin-js-sdk';
import submitImg from '../image/submit.png';
import Next from '../Component/Next';
import '../App.css';
import './submit.css';
import 'nprogress/nprogress.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { T } from 'antd/lib/upload/utils';

class SubmitPage extends React.Component {
  constructor(props) {
    super();
    this.onBackTOWinxin = this.onBackTOWinxin.bind(this);
    this.state = {

    };
  }

  componentDidMount() { //请求数据
    console.log(this.props)
  }

  onBackTOWinxin() {
    if (this.props.location.state.back == 2) {
      window.wx.miniProgram.navigateBack({
        delta: 2
      });
    } else {
      window.wx.miniProgram.navigateBack();
    }
  }

  onGoNext() {
    this.props.history.replace({
      pathname: '/?' + this.props.location.state.herfValue,
    });
  }

  render() {
    let title = '返回列表';
    if (this.props.location.state.conversation == 'conversation' && this.props.location.state.status == '0') {
      title = '发送';
    }
    return (
      <div className="">
        <Row justify="">
          <Col xs={15} >
            <div className="floating">
              <div><span className="color-333" style={{ fontSize: 14 }}>评测结果</span> </div>
              {this.props.location.state.name !== '高血压生活干预问卷' &&
                <div className="" style={{ marginTop: 20, color: this.props.location.state.color }}>
                  <span className="fs28">{this.props.location.state.warnResult}</span>
                </div>
              }
              <div className="">
                {this.props.location.state.name !== '高血压生活干预问卷' && this.props.location.state.totalScore != "0" &&
                  <div className="" style={{ marginTop: 12 }}>
                    <span className="color-333" style={{ fontSize: 14 }}>该问诊表得分: </span> <span className="fs14">{this.props.location.state.totalScore}分</span>
                  </div>
                }
                {this.props.location.state.name == '高血压生活干预问卷' &&
                  <div className="" style={{ marginTop: 20, color: this.props.location.state.color }}>
                    <span className="fs28">{this.props.location.state.name}</span>
                  </div>
                }
              </div>
            </div>
            {
              <div className="floating bottom" style={{ marginTop: 20 }}>
                <div className="color-333" style={{ fontSize: 16, fontWeight: 600 }}>评测建议</div>
                <div className="color-333" style={{ fontSize: 14, marginTop: 20 }}>若有不良症状，请及时前往医院，积极配合医生的治疗，作为患者的家属应该做好安全防范措施。</div>
              </div>
            }
          </Col>
        </Row>
      </div>
    );
  }
}

export default SubmitPage;
