import React from 'react';
import { Row, Col} from 'antd';
// import wx from 'weixin-js-sdk';
import submitImg from '../image/submit.png';
import axios from 'axios';
import Next from '../Component/Next';
import '../App.css';
import './result.css';
import 'nprogress/nprogress.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class PhysicalExaminationResult extends React.Component {
  constructor(props) {
    super();
    this.onBackTOWinxin = this.onBackTOWinxin.bind(this);
    this.getHealthGroupDiagnoseResultResultListByGroupDataId = this.getHealthGroupDiagnoseResultResultListByGroupDataId.bind(this);
    this.state = {
      data:[],
    };
  }


  componentDidMount(){ //请求数据
   console.log(this.props);
    this.setState({ info: this.props.location.state }, () => this.getHealthGroupDiagnoseResultResultListByGroupDataId());
  }

  getHealthGroupDiagnoseResultResultListByGroupDataId() {
    const that = this;
    return axios({
      method: "GET",
      url: that.state.info.baseURL + "healthGroup/diagnoseResult/client/getHealthGroupDiagnoseResultResultListByGroupDataId/" + that.state.info.groupDataId,
      headers:{token:this.state.info.token}
    }).then(function (res) {
      console.log(res,'getHealthGroupDiagnoseResultResultListByGroupDataId');
      if(res.data.status === 0) {
        that.setState({ 
          data:res.data.data,
        }, () => console.log(that.state));
        // return res.data.status
      } else {
        
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  onBackTOWinxin() {
    if(this.props.location.state.back == 2) {
      window.wx.miniProgram.navigateBack({
        delta: 2
      });
    } else {
      window.wx.miniProgram.navigateBack();
    }
  }

  render() {
    let title = '联系医生';
   
    return (
      <div className="App">
      <Row justify="center">
          <Col xs={15} >
            <div className="page">
              <div className="flex-column-center">
                <div className="image" style={{display:"inline-block"}}>
                  <img src={submitImg} alt="single3" style={{display:"inline-block"}} className="img-WH"/>
                </div>
                <div className="title1">
                  <span className="fs20 color-115 fw500">体检健康问卷已完成</span>
                </div>

                <div className="title2">
                  <span className="fs15 color-333 fw400">通过健康筛查的选择，针对您的身体状况，我们建议您的体检项需要包含以下检查项目：</span>
                </div>
              </div>
              <div className="flex-column-center">
               
                {   
                  this.state.data.map((item,index) => (
                    <div className="bor flex-column-center fs15 color-333 fw400" key={index}>
                    {item.diagnoseName}
                    </div>
                  ))
                }
                
               
              </div>
            </div>
            {  this.props.location.state.formPage !== 'b' &&
            
              <Next title={title} data={this.props.location.state} onSlickNext={this.onBackTOWinxin}></Next>
            }

            <div className="placeholder1">
              
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PhysicalExaminationResult;
