import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { getKeyThenIncreaseKey } from "antd/lib/message";
import axios from 'axios';
export default class GrowthCurveResultPage extends Component {

  constructor(props) {
    super();
    this.state ={}
  
  }


  componentDidMount(){ 
    this.initData();
    console.log(this);
    this.setState({ info: this.props.location.state }, () => {
       this.getUserData(1)
       this.getUserData(2)
      });
   }

   getUserData(type){
    const that = this;
    let data ={
      "solutionId": this.state.info.solutionId,
      "inputType": type,
      "patientId":this.state.info.patientId
    }
    return axios({
      method: "POST",
      url: this.state.info.baseURL + "solution/healthSolutionInputData/client/getHealthSolutionInputDataByWeek" ,
      data:data,
      headers:{token:this.state.info.token}
    }).then(function (res) {
      console.log("res",res.data.status)
      if(res.data.status==0){
        that.setState({
          userdata :[],
          data:that.initUserData(type,res.data)
        })
      }
   
    })
    .catch(function (error) {
      console.log(error);
    });
   }
   initUserData(type,data){
     if(type==1){
      let option = this.getChartOption(data.data)
       this.setState({
         hChartOption: option
       })
     }else{
      let option = this.getChartOption2(data.data)
      this.setState({
        wChartOption:option
      })
     }
   }

  initData() {
    let bhdata = [
      [
        53.4, 58.4, 62.2, 65.3, 67.8, 69.9, 71.6, 73.2, 74.7, 76.2, 77.6, 78.9,
        80.2, 81.5, 82.7, 83.9, 85.1, 86.2, 87.3, 88.4, 89.5, 90.5, 91.6, 92.6,
        93.6, 93.8, 94.8, 95.7, 96.6, 97.5, 98.3, 99.2, 100, 100.8, 101.5,
        102.3, 103.1,
      ],
      [
        51.8, 56.7, 60.5, 63.5, 66, 68.1, 69.8, 71.4, 72.9, 74.3, 75.6, 77,
        78.2, 79.4, 80.6, 81.8, 82.9, 84, 85.1, 86.1, 87.1, 88.1, 89.1, 90, 91,
        91.2, 92.1, 93, 93.8, 94.7, 95.5, 96.2, 97, 97.8, 98.5, 99.2, 99.9,
      ],
      [
        49.9, 54.7, 58.4, 61.4, 63.9, 65.9, 67.6, 69.2, 70.6, 72, 73.3, 74.5,
        75.7, 76.9, 78, 79.1, 80.2, 81.2, 82.3, 83.2, 84.2, 85.1, 86, 86.9,
        87.8, 88, 88.8, 89.6, 90.4, 91.2, 91.9, 92.7, 93.4, 94.1, 94.8, 95.4,
        96.1,
      ],
      [
        47.9, 52.7, 56.4, 59.3, 61.7, 63.7, 65.4, 66.9, 68.3, 69.6, 70.9, 72.1,
        73.3, 74.4, 75.5, 76.5, 77.5, 78.5, 79.5, 80.4, 81.3, 82.2, 83, 83.8,
        84.6, 84.7, 85.5, 86.3, 87, 87.7, 88.4, 89.1, 89.7, 90.4, 91, 91.6,
        92.2,
      ],
      [
        46.3, 51.1, 54.7, 57.6, 60, 61.9, 63.6, 65.1, 66.5, 67.7, 69, 70.2,
        71.3, 72.4, 73.4, 74.4, 75.4, 76.3, 77.2, 78.1, 78.9, 79.7, 80.5, 81.3,
        82.1, 82.1, 82.8, 83.5, 84.2, 84.9, 85.5, 86.2, 86.8, 87.4, 88, 88.5,
        89.1,
      ],
    ];
    let bwdata = [
      [
        4.3, 5.7, 7, 7.9, 8.6, 9.2, 9.7, 10.2, 10.5, 10.9, 11.2, 11.5, 11.8,
        12.1, 12.4, 12.7, 12.9, 13.2, 13.5, 13.7, 14, 14.3, 14.5, 14.8, 15.1,
        15.3, 15.6, 15.9, 16.1, 16.4, 16.6, 16.9, 17.1, 17.3, 17.6, 17.8, 18,
      ],
      [
        3.9, 5.1, 6.3, 7.2, 7.9, 8.4, 8.9, 9.3, 9.6, 10, 10.3, 10.5, 10.8, 11.1,
        11.3, 11.6, 11.8, 12, 12.3, 12.5, 12.7, 13, 13.2, 13.4, 13.7, 13.9,
        14.1, 14.4, 14.6, 14.8, 15, 15.2, 15.5, 15.7, 15.9, 16.1, 16.3,
      ],
      [
        3.3, 4.5, 5.6, 6.4, 7, 7.5, 7.9, 8.3, 8.6, 8.9, 9.2, 9.4, 9.6, 9.9,
        10.1, 10.3, 10.5, 10.7, 10.9, 11.1, 11.3, 11.5, 11.8, 12, 12.2, 12.4,
        12.5, 12.7, 12.9, 13.1, 13.3, 13.5, 13.7, 13.8, 14, 14.2, 14.3,
      ],
      [
        2.9, 3.9, 4.9, 5.6, 6.2, 6.7, 7.1, 7.4, 7.7, 7.9, 8.2, 8.4, 8.6, 8.8, 9,
        9.2, 9.4, 9.6, 9.7, 9.9, 10.1, 10.3, 10.5, 10.6, 10.8, 11, 11.1, 11.3,
        11.5, 11.6, 11.8, 11.9, 12.1, 12.2, 12.4, 12.5, 12.7,
      ],
      [
        2.5, 3.4, 4.4, 5.1, 5.6, 6.1, 6.4, 6.7, 7, 7.2, 7.5, 7.7, 7.8, 8, 8.2,
        8.4, 8.5, 8.7, 8.9, 9, 9.2, 9.3, 9.5, 9.7, 9.8, 10, 10.1, 10.2, 10.4,
        10.5, 10.7, 10.8, 10.9, 11.1, 11.2, 11.3, 11.4,
      ],
    ];
    let ghdata = [
      [
        52.7, 57.4, 60.9, 63.8, 66.2, 68.2, 70, 71.6, 73.2, 74.7, 76.1, 77.5,
        78.9, 80.2, 81.4, 82.7, 83.9, 85, 86.2, 87.3, 88.4, 89.4, 90.5, 91.5,
        92.5, 92.8, 93.7, 94.6, 95.6, 96.4, 97.3, 98.2, 99, 99.8, 100.6, 101.4,
        102.2,
      ],
      [
        51.1, 55.7, 59.2, 62, 64.3, 66.3, 68.1, 69.7, 71.2, 72.6, 74, 75.4,
        76.7, 77.9, 79.2, 80.3, 81.5, 82.6, 83.7, 84.8, 85.8, 86.8, 87.8, 88.8,
        89.8, 90, 90.9, 91.8, 92.7, 93.5, 94.3, 95.2, 95.9, 96.7, 97.5, 98.3,
        99,
      ],
      [
        49.1, 53.7, 57.1, 59.8, 62.1, 64, 65.7, 67.3, 68.7, 70.1, 71.5, 72.8,
        74, 75.2, 76.4, 77.5, 78.6, 79.7, 80.7, 81.7, 82.7, 83.7, 84.6, 85.5,
        86.4, 86.6, 87.4, 88.3, 89.1, 89.9, 90.7, 91.4, 92.2, 92.9, 93.6, 94.4,
        95.1,
      ],
      [
        47.2, 51.7, 55, 57.6, 59.8, 61.7, 63.4, 64.9, 66.3, 67.6, 68.9, 70.2,
        71.3, 72.5, 73.6, 74.7, 75.7, 76.7, 77.7, 78.7, 79.6, 80.5, 81.4, 82.2,
        83.1, 83.2, 84, 84.8, 85.5, 86.3, 87, 87.7, 88.4, 89.1, 89.8, 90.5,
        91.1,
      ],
      [
        45.6, 50, 53.2, 55.8, 58, 59.9, 61.5, 62.9, 64.3, 65.6, 66.8, 68, 69.2,
        70.3, 71.3, 72.4, 73.3, 74.3, 75.2, 76.2, 77, 77.9, 78.7, 79.6, 80.3,
        80.4, 81.2, 81.9, 82.6, 83.4, 84, 84.7, 85.4, 86, 86.7, 87.3, 87.9,
      ],
    ];
    let gwdata = [
      [
        4.2, 5.4, 6.5, 7.4, 8.1, 8.7, 9.2, 9.6, 10, 10.4, 10.7, 11, 11.3, 11.6,
        11.9, 12.2, 12.5, 12.7, 13, 13.3, 13.5, 13.8, 14.1, 14.3, 14.6, 14.9,
        15.2, 15.4, 15.7, 16, 16.2, 16.5, 16.8, 17, 17.3, 17.6, 17.8,
      ],
      [
        3.7, 4.8, 5.9, 6.7, 7.3, 7.8, 8.3, 8.7, 9, 9.3, 9.6, 9.9, 10.2, 10.4,
        10.7, 10.9, 11.2, 11.4, 11.6, 11.9, 12.1, 12.4, 12.6, 12.8, 13.1, 13.3,
        13.6, 13.8, 14, 14.3, 14.5, 14.7, 15, 15.2, 15.4, 15.7, 15.9,
      ],
      [
        3.2, 4.2, 5.1, 5.8, 6.4, 6.9, 7.3, 7.6, 7.9, 8.2, 8.5, 8.7, 8.9, 9.2,
        9.4, 9.6, 9.8, 10, 10.2, 10.4, 10.6, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9,
        12.1, 12.3, 12.5, 12.7, 12.9, 13.1, 13.3, 13.5, 13.7, 13.9,
      ],
      [
        2.8, 3.6, 4.5, 5.1, 5.6, 6.1, 6.4, 6.7, 7, 7.3, 7.5, 7.7, 7.9, 8.1, 8.3,
        8.5, 8.7, 8.8, 9, 9.2, 9.4, 9.6, 9.8, 9.9, 10.1, 10.3, 10.5, 10.7, 10.8,
        11, 11.2, 11.3, 11.5, 11.7, 11.8, 12, 12.1,
      ],
      [
        2.4, 3.2, 4, 4.6, 5.1, 5.5, 5.8, 6.1, 6.3, 6.6, 6.8, 7, 7.1, 7.3, 7.5,
        7.7, 7.8, 8, 8.2, 8.3, 8.5, 8.7, 8.8, 9, 9.2, 9.3, 9.5, 9.6, 9.8, 10,
        10.1, 10.3, 10.4, 10.5, 10.7, 10.8, 11,
      ],
    ];
    let bdata = [bhdata, bwdata];
    let gdata = [ghdata, gwdata];
 
    let bMoreData = [];
    let gMoreData = [];
    bdata.forEach((d) => {
      let realData = [];
      d.forEach((e) => {
        let newarr = [];
        for (let index = 0; index < e.length; index++) {
          newarr.push([4*index,e[index]]);
        }
        realData.push(newarr);
      });
      bMoreData.push(realData);
    });
    gdata.forEach((d) => {
      let realData = [];
      d.forEach((e) => {
        let newarr = [];
        for (let index = 0; index < e.length; index++) {
          newarr.push([4*index,e[index]]);
          
        }
      
        realData.push(newarr);
      });
      gMoreData.push(realData);
    });
   this.setState({
    gMoreData:gMoreData,
    bMoreData:bMoreData
   })
  };
   getFloat = function (num, n) {
    n = n ? parseInt(n) : 0;
    if (n <= 0) {
      return Math.round(num);
    }
    num = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); //四舍五入
    num = Number(num).toFixed(n); //补足位数
    return Number(num);
  };
  getChartOption(data){
    console.log("getChartOption",data)
    let alldata;
    if(data.sex==0){
      alldata =  this.state.bMoreData[0]
    }else{
      alldata =  this.state.gMoreData[0]
    }
    let xdata =[];
    let userData =[];
    for (let index = 0; index < 144; index++) {
      xdata.push(index)
    }
    for (let index = 0; index <  data.curveData.length; index++) {
      if( data.curveData[index]>0){
        userData.push([index,data.curveData[index]])
      }
   
    }
    console.log("userData",userData)
      return {
        dataZoom: {
          id: 'dataZoomY',
          show:true,                              //是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
          backgroundColor:"rgba(47,69,84,0)",  //组件的背景颜色
          type: 'inside',                         //slider表示有滑动块的，inside表示内置的
          fillerColor:"rgba(167,183,204,0.4)",  //选中范围的填充颜色。
          borderColor:"#ddd",                     //边框颜色。
          filterMode: 'empty',             //'filter'：当前数据窗口外的数据，被 过滤掉。即 会 影响其他轴的数据范围。每个数据项，只要有一个维度在数据窗口外，整个数据项就会被过滤掉。
          xAxisIndex:0,                            //设置 dataZoom-inside 组件控制的 x轴,可以用数组表示多个轴
          // yAxisIndex:0,                        //设置 dataZoom-inside 组件控制的 y轴,可以用数组表示多个轴
          radiusAxisIndex:3,                       //设置 dataZoom-inside 组件控制的 radius 轴,可以用数组表示多个轴
          angleAxisIndex:[0,2],                    //设置 dataZoom-inside 组件控制的 angle 轴,可以用数组表示多个轴
          start: 0,                                //数据窗口范围的起始百分比,表示30%
          end: 70,                                  //数据窗口范围的结束百分比,表示70%
          startValue:10,                           //数据窗口范围的起始数值
          endValue:100,                            //数据窗口范围的结束数值。
          orient:"vertical",                    //布局方式是横还是竖。不仅是布局方式，对于直角坐标系而言，也决定了，缺省情况控制横向数轴还是纵向数轴。'horizontal'：水平。'vertical'：竖直。
          zoomLock:false,                          //是否锁定选择区域（或叫做数据窗口）的大小。如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
          throttle:100,                             //设置触发视图刷新的频率。单位为毫秒（ms）。
          zoomOnMouseWheel:true,                  //如何触发缩放。可选值为：true：表示不按任何功能键，鼠标滚轮能触发缩放。false：表示鼠标滚轮不能触发缩放。'shift'：表示按住 shift 和鼠标滚轮能触发缩放。'ctrl'：表示按住 ctrl 和鼠标滚轮能触发缩放。'alt'：表示按住 alt 和鼠标滚轮能触发缩放。
          moveOnMouseMove:true,                   //如何触发数据窗口平移。true：表示不按任何功能键，鼠标移动能触发数据窗口平移。false：表示鼠标滚轮不能触发缩放。'shift'：表示按住 shift 和鼠标移动能触发数据窗口平移。'ctrl'：表示按住 ctrl 和鼠标移动能触发数据窗口平移。'alt'：表示按住 alt 和鼠标移动能触发数据窗口平移。
          left:"center",                           //组件离容器左侧的距离,'left', 'center', 'right','20%'
          top:"bottom",                                //组件离容器上侧的距离,'top', 'middle', 'bottom','20%'
          right:"auto",                             //组件离容器右侧的距离,'20%'
          bottom:"auto",                            //组件离容器下侧的距离,'20%'
        },
        title: {
          text: "儿童成长百分位图-身高",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "8%",
          data: ["3%","15%","50%","85%","97%","您的数据"],
        },
        color:['#ff003e', '#ff7300', '#00bb1b', '#ff7300', '#ff003e', '#0098ef'],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
         
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            onZero: false,
          },
          axisPointer: {
            label: {
              formatter: function (params) {
                return "第" + params.value + "周";
              },
            },
          },
          data:xdata,
        },
        yAxis: [
          {
            type: "value",
            min:40
          },
        ],
        series: [
          {
            name: "3%",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: alldata[4],
            itemStyle: {
              normal: {
                  color: '#ff003e',
                  lineStyle:{
                    width:0.5
                  }
              }},
          },
          {
            name: "15%",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: alldata[3],
            itemStyle: {
              normal: {
                  color: '#ff7300',
                  lineStyle:{
                    width:0.5
                  }
              }},
          },
          {
            name: "50%",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: alldata[2],
            itemStyle: {
              normal: {
                  color: '#00bb1b',
                  lineStyle:{
                    width:0.5
                  }
              }},
          },
          {
            name: "85%",
            type: "line",
            smooth: true,
            showSymbol: false,
            itemStyle: {
              normal: {
                  color: '#ff7300',
                  lineStyle:{
                    width:0.5
                  }
              }},
            data: alldata[1],
          },
          {
            name: "97%",
            type: "line",
            smooth: true,
            showSymbol: false,
            itemStyle: {
              normal: {
                  color: '#ff003e',
                  lineStyle:{
                    width:0.5
                  }
              }},
            data:alldata[0],
          },
          {
            name: "我的数据",
            type:userData.length > 1 ? "line":"scatter",
            smooth: true,
            showSymbol: true,
            itemStyle: {
              normal: {
                  color: '#0098ef',
                  lineStyle:{
                      width:0.5
                  }
              }},
            data: userData,
          },
        ],
      };
   
    
  };
  getChartOption2(data){
    console.log("getChartOption2",data)
    let alldata;
    if(data.sex==0){
      alldata =  this.state.bMoreData[1]
    }else{
      alldata =  this.state.gMoreData[1]
    }
   
    let xdata =[];
    let userData =[];
    for (let index = 0; index < 145; index++) {
      xdata.push(index)
    }
    for (let index = 0; index < data.curveData.length; index++) {
      if( data.curveData[index]>0){
        userData.push([index,data.curveData[index]])
      }}
      return {
        dataZoom: {
          id: 'dataZoomY',
          show:true,                              //是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
          backgroundColor:"rgba(47,69,84,0)",  //组件的背景颜色
          type: 'inside',                         //slider表示有滑动块的，inside表示内置的
          fillerColor:"rgba(167,183,204,0.4)",  //选中范围的填充颜色。
          borderColor:"#ddd",                     //边框颜色。
          filterMode: 'empty',             //'filter'：当前数据窗口外的数据，被 过滤掉。即 会 影响其他轴的数据范围。每个数据项，只要有一个维度在数据窗口外，整个数据项就会被过滤掉。
          xAxisIndex:0,                            //设置 dataZoom-inside 组件控制的 x轴,可以用数组表示多个轴
          // yAxisIndex:0,                        //设置 dataZoom-inside 组件控制的 y轴,可以用数组表示多个轴
          radiusAxisIndex:3,                       //设置 dataZoom-inside 组件控制的 radius 轴,可以用数组表示多个轴
          angleAxisIndex:[0,2],                    //设置 dataZoom-inside 组件控制的 angle 轴,可以用数组表示多个轴
          start: 0,                                //数据窗口范围的起始百分比,表示30%
          end: 70,                                  //数据窗口范围的结束百分比,表示70%
     
          orient:"vertical",                    //布局方式是横还是竖。不仅是布局方式，对于直角坐标系而言，也决定了，缺省情况控制横向数轴还是纵向数轴。'horizontal'：水平。'vertical'：竖直。
          zoomLock:false,                          //是否锁定选择区域（或叫做数据窗口）的大小。如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
          throttle:100,                             //设置触发视图刷新的频率。单位为毫秒（ms）。
          zoomOnMouseWheel:true,                  //如何触发缩放。可选值为：true：表示不按任何功能键，鼠标滚轮能触发缩放。false：表示鼠标滚轮不能触发缩放。'shift'：表示按住 shift 和鼠标滚轮能触发缩放。'ctrl'：表示按住 ctrl 和鼠标滚轮能触发缩放。'alt'：表示按住 alt 和鼠标滚轮能触发缩放。
          moveOnMouseMove:true,                   //如何触发数据窗口平移。true：表示不按任何功能键，鼠标移动能触发数据窗口平移。false：表示鼠标滚轮不能触发缩放。'shift'：表示按住 shift 和鼠标移动能触发数据窗口平移。'ctrl'：表示按住 ctrl 和鼠标移动能触发数据窗口平移。'alt'：表示按住 alt 和鼠标移动能触发数据窗口平移。
        },
        title: {
          text: "儿童成长百分位图-体重",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "8%",
          data: ["3%","15%","50%","85%","97%","您的数据"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
         
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            onZero: false,
          },
          axisPointer: {
            label: {
              formatter: function (params) {
                return "第" + params.value + "周";
              },
            },
          },
          data: xdata,
        },
        yAxis: [
          {
            type: "value",
            min:2
          },
        ],
        series: [
          {
            name: "3%",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: alldata[4],
            itemStyle: {
              normal: {
                  color: '#ff003e',
                  lineStyle:{
                    width:0.5
                  }
              }},
          },
          {
            name: "15%",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: alldata[3],
            itemStyle: {
              normal: {
                  color: '#ff7300',
                  lineStyle:{
                    width:0.5
                  }
              }},
          },
          {
            name: "50%",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: alldata[2],
            itemStyle: {
              normal: {
                  color: '#00bb1b',
                  lineStyle:{
                    width:0.5
                  }
              }},
          },
          {
            name: "85%",
            type: "line",
            smooth: true,
            showSymbol: false,
            itemStyle: {
              normal: {
                  color: '#ff7300',
                  lineStyle:{
                    width:0.5
                  }
              }},
            data: alldata[1],
          },
          {
            name: "97%",
            type: "line",
            smooth: true,
            showSymbol: false,
            itemStyle: {
              normal: {
                  color: '#ff003e',
                  lineStyle:{
                    width:0.5
                  }
              }},
            data:alldata[0],
          },
          {
            name: "我的数据",
            type:userData.length>1? "line":"scatter",
            smooth: true,
            showSymbol: true,
            itemStyle: {
              normal: {
                  color: '#0098ef',
                  lineStyle:{
                      width:0.5
                  }
              }},
            data: userData,
          },
        ],
      };
  };

  render() {
   console.log("render",this.state)
    let doption ={
      title: {
        text: "儿童成长百分位图",
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        top: "8%",
        data: ["3%","15%","50%","85%","97%","您的数据"],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
      
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          onZero: false,
        },
        axisPointer: {
          label: {
            formatter: function (params) {
              return "第" + params.value + "周";
            },
          },
        },
        data: [],
      },
      yAxis: [
        {
          type: "value",
          min: 2,
        },
      ],
   
    }
    return (
      <div>
        <ReactEcharts option={this.state.hChartOption??doption}></ReactEcharts>
        <ReactEcharts option={this.state.wChartOption??doption}></ReactEcharts>
      </div>
    );
  }
}
