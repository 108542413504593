import React from 'react';
import './App.css';
import Problem from './Component/problem';
import SubmitPage from './submit/submitPage';
import PhysicalExaminationResult from './physical-examination/physical-examination-result'
import GrowthCurveResultPage from './ResultPage/growth-curve'
import { BrowserRouter as Router, Route } from "react-router-dom";
import VConsole from 'vconsole';


function App() {

  if (window.location.search.indexOf('envConsole=1') > 0) {
    new VConsole({ target: 'vcsonsole' });
  }

  return (
    <div>
      <Router basename='/quest'>
        <Route exact path="/" component={Problem} />
        <Route exact path="/submit" component={SubmitPage} />
        <Route exact path="/physicalExaminationResult" component={PhysicalExaminationResult} />
        <Route exact path="/growthCurveResultPage" component={GrowthCurveResultPage} />
        {/* <Route exact path="/score" component={ScoreList} /> */}
      </Router>
    </div>
  );
}

export default App;
